import 'globalStyles/normalize.scss';
import * as Markup from 'globalStyles/markup';
import Footer from 'templates/footer/Footer';
import GetInTouch from 'templates/get-in-touch/GetInTouch';
import Head from 'templates/head/Head';
import Header from 'components/header/components/Header';
import { useAppNavigation } from '../../hooks/useAppNavigation';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import SEO from 'components/seo/SEO';
import Terms from 'templates/terms/Terms';

export default function Privacy() {
  const location = useLocation();
  useAppNavigation({ location });

  return (
    <Markup.PageWrapper>
      <SEO />
      <Markup.GlobalStyle />
      <Markup.MainContainer>
        <Header />
        <Head type="privacy" />
        <Markup.Main>
          <Terms />
          <GetInTouch />
          <Footer />
        </Markup.Main>
      </Markup.MainContainer>
    </Markup.PageWrapper>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
