import { SectionDescription } from 'components/section/styles/styled';
import useTerms from './useTerms';

const styles = {
  marginBottom: '108px',
};

const Terms = () => {
  const { terms, privacy } = useTerms();

  return (
    <>
      <SectionDescription
        style={styles}
        dangerouslySetInnerHTML={{ __html: terms }}
      />
      <SectionDescription
        style={styles}
        dangerouslySetInnerHTML={{ __html: privacy }}
      />
    </>
  );
};

export default Terms;
