import { regex } from '../constants/regex';

const REPLACE_VALUE = {
  line_break: '<br />',
};

export const replaceTermsString = (text: string) =>
  text
    ?.replace(regex.LINE_BREAKS, REPLACE_VALUE.line_break)
    .replace(regex.BACK_QUOTE, '')
    .replace('html', '');
