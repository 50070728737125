import { graphql, useStaticQuery } from 'gatsby';
import { replaceTermsString } from '../../utils/replaceTermsString';
import { IPrivacyResponse } from '../../interfaces/interfaces';

const useTerms = () => {
  const {
    markdownRemark: {
      frontmatter: {
        english: { terms, privacy },
      },
    },
  } = useStaticQuery<IPrivacyResponse>(graphql`
    query privacy {
      markdownRemark(frontmatter: { type: { eq: "privacy" } }) {
        frontmatter {
          english {
            terms
            privacy
          }
        }
      }
    }
  `);

  return {
    terms: replaceTermsString(terms),
    privacy: replaceTermsString(privacy),
  };
};

export default useTerms;
